<template>
    <div class="container">
        <div class="section">
            <h1 class="title">Workshop Archive</h1>
        </div>
        <div style="margin-top: 0px; margin-left: 30px; margin-bottom: 30px">
                <div align="center">
                    <div class="columns is-multiline">
                        <div class="column is-4" v-for="(ws, i) in workshop" :key="i">
                            <router-link :to="'/workshop/' + ws.id">
                                <div class="card has-equal-height" v-if="ws.public == true">
                                    <img
                                        :src="ws.hero"
                                        :alt="ws.name"
                                        style="width: auto; margin: auto; width: 50%"
                                    />
                                    <br />
                                    <h1 class="subtitle">{{ws.name}}</h1>
                                    {{ws.host}}
                                    <br/>
                                    <vue-markdown>{{ws.location}}</vue-markdown>
                                    <br/>
                                </div>
                            </router-link>
                        </div>
                    </div>
                    <div></div>
                </div>
        </div>
    </div>
</template>

<script>
import workshops from "@/workshops.js";
import _ from "lodash";
import VueMarkdown from 'vue-markdown'

export default {
    data() {
        return {
            workshop: _.sortBy(_.filter(workshops, "public")),
        };
    },
    components:{
        VueMarkdown,
    },
};
</script>

<style lang="scss" scoped>
.has-equal-height {
    height: 100%;
    display: flex;
    flex-direction: column;
}
</style>