<template>
    <div class="archive">
        <Archive />
    </div>
</template>

<script>
import Archive from '@/components/Archive.vue'
export default {
    name: 'workshops',
    components: {
        Archive
    }
}
</script>